import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import photoDartLogo from './images/photoDartLogo.svg'
import { removeStates, removeStates2, addToHistory, addToHistory2} from "./components/CanvasHistory";
import GrowExample from './components/spinner'
import { fabric } from "fabric";
import LoadingOverlay from 'react-loading-overlay';
import {Row, Container} from "reactstrap";
import { getCanvasBySide } from "./components/Helpers";
import { Tabs, Tab, TabList } from "react-web-tabs";
import FabricCanvas from "./components/FabricCanvas";
import Toolbar from "./components/Toolbar";
import LeftPanel from "./components/LeftPanel";
import appconfig from "../config";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import {WP_LOGIN, CartLogin}  from './constants/index'
import html2canvas from 'html2canvas';
import { BsFillLayersFill } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { MdModeEditOutline } from "react-icons/md";
import MobileLeftPanel from './components/MobileLeftPanel'
import { AiOutlineShoppingCart } from "react-icons/ai";
import "./App.scss";
import "./styles/TabView.scss";
import "./styles/LeftSidePanel.scss";
import "./styles/FabricCanvas.scss";
import "./styles/Toolbar.scss";
import "./components/modal.css";
import "./styles/Responsivemedia.scss";
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import piexif from 'piexifjs';

fabric.Canvas.prototype.aspectRation = 1;
class App extends Component {
    constructor(props) {
        super(props);
        this.setMask = React.createRef();
        this.state = {
            allCanvasList: [],
            isMasking: false,
            canvas: null,
            canvasObj: [],
            setImage: null,
            isSnap: false,
            maskTab: false,
            isOverlap: false,
            productType: null,
            bookmarkType: "1",
            bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
            isGrid: false,
            sidebarWidth: 367,
            canvaswidth: "",
            canvasheight: "",
            fontBoldValue: "normal",
            fontStrikeValue: "",
            fontItalicValue: "normal",
            fontUnderlineValue: "",
            collapse: window.innerWidth > 990,
            gridsize: 30,
            imggallery: [],
            top: "",
            isOpen: false,
            currentBookmark: "",
            templateKey: null,
            activeTab: "1",
            toolbarVisible: false,
            toggle: false,
            nameToggle: false,
            editNameToggle: false,
            localKey: null,
            designData: null,
            idData: null,
            projectName: null,
            projectData: null,
            projectType: null,
            type: null,
            editId: null,
            isUsed: false,
            usedWord: null,
            userObject: {},
            bullet: false,
            prevZoom: null,
            prodId: null,
            imageObjects : [],
            selectedImages : [],
            canvasPreview : false,
            previewImage : '',
            isLoader : true,
            appLoader : false,
            loaderMessage : '',
            adminCanvasData : {},
            isAdmin : false,
            backgroundColor : '',
            sortedArray : [],
            draglayer : false,
            cartWarning : false,
            saveWarning : false,
            saveCanvasZoom : {
                canvasWidth : '',
                canvasHeight : '',
                zoomlevel : ''
            },
            cartLoader : false,
            continueButton : false,
            cartLoaderMessage : '',
            imageIds : [],
            previewLoader : [],
            productDimensions : '',
            productPrice : '',
            productTips : '',
            productMaterial : '',
            quantity : 1,
            design_unique_id : null,
            ClearWarning : false,
            mobilesidebar : false,
            disableButton : false,
            searchQuery: "",
            responsive : '',
            imageId : [],
            isSpin : false,
            selectedtab : '',
            isDeleted : false,
            deleteMessage : ''
        };
        this.currentBookmark = this.currentBookmark.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.scrollToTop();
        const query = new URLSearchParams(window.location.search);
        let designID = query.get('designId')
        fabric.enableGLFiltering = false;   
        if (designID) {
            this.setState({ prodId: designID });
        }
        this.currentBookmark();
        if (!designID) {
            this.setState({ toggle: true })
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const query = new URLSearchParams(window.location.search);
        let userId = query.get("u");
        if (prevState.canvas !== this.state.canvas) {
            this.state.canvas.off("selection:created", this.funcSelectionCreated);
            this.state.canvas.on("selection:created", this.funcSelectionCreated);
            this.state.canvas.off("selection:updated", this.funcSelectionUpdated);
            this.state.canvas.on("selection:updated", this.funcSelectionUpdated);
            this.state.canvas.off("selection:cleared", this.funcSelectionCleared);
            this.state.canvas.on("selection:cleared", this.funcSelectionCleared);
            this.setState({
                sortedArray : this.state.canvas._objects
            })
        }
        if (prevState.usedWord !== this.state.usedWord) {
            let apiDynamicUrlAddress = appconfig.default.api.host;

            let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`;
            await axios.get(apiUrl2).then((response) => {
                this.setState({ designData: response.data.data });
            });
            let savedName = await this.state.designData.filter((item) => {
                return item.designName === this.state.usedWord;
            });
            if (savedName.length > 0) {
                this.setState({ isUsed: true });
            } else {
                this.setState({ projectName: this.state.usedWord });
                this.setState({ isUsed: false });
            }
        }
    };

    funcSelectionCreated = () => {
        this.setState({ toolbarVisible: true });
        this.setState({
            sortedArray : this.state.canvas._objects
        })
        this.state.canvas.requestRenderAll();
    }

    funcSelectionUpdated = () => {
        this.setState({ toolbarVisible: true });
        this.state.canvas.requestRenderAll();
    }

    funcSelectionCleared = () => {
        this.setState({ toolbarVisible: false });
        this.state.canvas.requestRenderAll();
    }

    currentBookmark = async (currentLocc = null) => {
        const query = new URLSearchParams(window.location.search);
        const type = query.get("type");
        const pid = query.get("pid");
        const userId = query.get("u");
        const token = query.get("token");
        const cs = query.get("cs");
        const duid = query.get("duid");
        let currentLoc = this.state?.productType
            ? this.state.productType
            : type;
        this.setState({
            currentBookmark: currentLoc,
        });
        if(pid)
        {
            const headers = {
                "Content-Type": "application/json;charset=UTF-8",
            };
            let apiDynamicUrlAddress = appconfig.default.api.host;
            let apiUrl;
            if(userId)
            {
                apiUrl = `${apiDynamicUrlAddress}/get-product-info/?product_id=${pid}&user_id=${userId}`;
            }else{
                apiUrl = `${apiDynamicUrlAddress}/get-product-info/?product_id=${pid}`;
            }
            
            try {
                const response = await axios.get(apiUrl, {
                    mode: "cors",
                    headers: headers,
                });
                const code = response.data.data.product_details.product_dimensions;
                const dimensions = code.split('-')[1];
                const canvastype = code.split('-')[0];
                const [width, height] = dimensions.split('x').map(parseFloat);
                this.setState({
                    canvaswidth: width,
                    canvasheight: height,
                    canvastype : canvastype,
                    productDimensions : dimensions,
                    productMaterial :  response.data.data.product_details.product_material ? response.data.data.product_details.product_material : 'NA',
                    productPrice : response.data.data.product_details.sale_price ? response.data.data.product_details.sale_price : response.data.data.product_details.regular_price,
                    productTips : response.data.data.product_details.tips,
                    instruction : response.data.data.product_details.instruction,
                    logoUrl : response.data.data.product_details.home_url
                });
                if(!userId && !token && pid && JSON.parse(localStorage.getItem('canvas'))){
                    let canvas = JSON.parse(localStorage.getItem('canvas'))
                    if(pid === canvas.productId)
                    {
                        localStorage.removeItem('canvas');
                        this.setState({
                            adminCanvasData :  canvas.json,
                            isLoader : false,
                        });
                    }else{
                        let apiDynamicUrlAddress1 = appconfig.default.api1.host;
                        let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin&v=${Date.now()}`;
                        try {
                            const response = await axios.get(apiUrl1, {
                                mode: "cors",
                                headers: headers,
                            });
                            if(response.data.code === 200)
                            {
                                let canvasdata = response.data.data[0].json
                                this.setState({
                                    adminCanvasData: canvasdata,
                                    isLoader: false,
                                });
                            }
                            else{
                                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                                this.setState({
                                    isLoader : false,
                                });
                            }
                        } catch (error) {
                            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    }
                }
                if (userId && token && JSON.parse(localStorage.getItem('canvas'))) {
                    let canvas = JSON.parse(localStorage.getItem('canvas'))
                    if(pid === canvas.productId && cs)
                    {
                        this.setState({
                            adminCanvasData :  canvas.json,
                            appLoader : true,
                            loaderMessage : 'Saving your Product...',
                            isLoader : false,
                        });
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            this.setState({
                                isAdmin : true
                            })
                        }
                        let uuid = this.state.design_unique_id ? this.state.design_unique_id : uuidv4();
                        // Get current date and time
                        const currentDate = new Date();
                        // Format the date and time
                        const year = currentDate.getFullYear();
                        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                        const day = String(currentDate.getDate()).padStart(2, '0');
                        const hours = String(currentDate.getHours()).padStart(2, '0');
                        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
                        // Create the formatted date and time string
                        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        let payload = {
                            "userId": userId ? userId : "23",
                            "role": response.data.data.product_details.user_role[0] === "administrator" ? "admin" : "user",
                            "name":"New design",
                            "productId": pid,
                            "date" : formattedDate,
                            "json": {
                                "version": "5.3.0",
                                "objects": canvas.json.objects,
                                "background": canvas.json.background,
                                "height":  canvas.json.height,
                                "width": canvas.json.width,
                            },
                            "imageDetails" : canvas.imageDetails,
                            "design_unique_id" : uuid
                        }
                        let apiDynamicUrlAddress = appconfig.default.api1.host;
                        let apiUrl = `${apiDynamicUrlAddress}/saveProductImage`;
                
                        try {
                            const response = await axios.post(apiUrl, payload);
                            if (response.status === 200) {
                                let did = response.data.Message.design_unique_id
                                this.setState({
                                    design_unique_id : did
                                })
                                toast.success("Product has been saved successfully.", { position: toast.POSITION.TOP_RIGHT });
                                localStorage.removeItem('canvas');
                                this.setState({
                                    appLoader : false
                                })
                            } else {
                                toast.error("Product has been saved successfully.", { position: toast.POSITION.TOP_RIGHT });
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }else{
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            this.setState({
                                isAdmin : true
                            })
                        }
                        let apiDynamicUrlAddress1 = appconfig.default.api1.host;
                        let apiUrl1;
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=admin&v=${Date.now()}`;
                        }else{
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=user&v=${Date.now()}`;
                        }
                        try {
                            const response = await axios.get(apiUrl1, {
                                mode: "cors",
                                headers: headers,
                            });
                            if(response.data.code === 200)
                            {
                                let canvasdata = response.data.data[0].json
                                this.setState({
                                    adminCanvasData: canvasdata,
                                    isLoader: false,
                                });
                            }
                            else{
                                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                                this.setState({
                                    isLoader : false,
                                });
                            }
                            this.imgGallery();
                        } catch (error) {
                            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    }
                }
                if (userId && token && !JSON.parse(localStorage.getItem('canvas'))) {
                    if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                    {
                        this.setState({
                            isAdmin : true
                        })
                    }
                    let apiDynamicUrlAddress1 = appconfig.default.api1.host;
                    let apiUrl1;
                    if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                    {
                        apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=admin&v=${Date.now()}`;
                    }else{
                        if(duid)
                        {
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=user&design_unique_id=${duid}&edit_cart=true&v=${Date.now()}`;
                        }else{
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=user&v=${Date.now()}`;
                        }
                    }
                    try {
                        const response = await axios.get(apiUrl1, {
                            mode: "cors",
                            headers: headers,
                        });
                        if(response.data.code === 200)
                        {   
                            let did = response.data && response.data.data[0] && response.data.data[0].design_unique_id && response.data.data[0].design_unique_id
                            this.setState({
                                design_unique_id : did
                            })
                            let canvasdata = response.data.data[0].json
                            this.setState({
                                adminCanvasData: canvasdata,
                                isLoader: false,
                            });
                        }
                        else{
                            toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                        this.imgGallery();
                    } catch (error) {
                        toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                        this.setState({
                            isLoader : false,
                        });
                    }
                }
                if (!userId && !token && pid && !JSON.parse(localStorage.getItem('canvas'))) {
                    let apiDynamicUrlAddress1 = appconfig.default.api1.host;
                    let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin&v=${Date.now()}`;
                    try {
                        const response = await axios.get(apiUrl1, {
                            mode: "cors",
                            headers: headers,
                        });
                        if(response.data.code === 200)
                        {
                            let canvasdata = response.data.data[0].json
                            this.setState({
                                adminCanvasData: canvasdata,
                                isLoader: false,
                            });
                        }
                        else{
                            toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    } catch (error) {
                        toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                        this.setState({
                            isLoader : false,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            this.setState({
                isLoader : false,
                canvaswidth: "11",
                canvasheight: "11",
            });
        }
    };
    setActiveTab = (activeTabKey) => {
        this.setState({
            activeTab: activeTabKey,
        });
    };
    setImages = (img) => {
        this.setState({ setImage: img });
    };
    openModal = () => {
        this.setState({
            continueButton : true
        })
        this.canvasToImage('modal')
    };
    // Function to add DPI to the image using piexifjs
    addDPIToImage = (base64Image, dpi, inches) => {
        try {
            // Check if the image contains EXIF data
            const exifObj = piexif.load(base64Image);

            // If no EXIF data exists, initialize an empty EXIF object
            if (!exifObj['0th']) {
                exifObj['0th'] = {};
            }

            // Set the DPI (150 DPI in this example)
            const density = [dpi, 1]; // DPI in x and y directions (150x150 DPI)
            exifObj['0th'][piexif.ImageIFD.XResolution] = density;
            exifObj['0th'][piexif.ImageIFD.YResolution] = density;
            exifObj['0th'][piexif.ImageIFD.ResolutionUnit] = 2; // 2 means "inches"

            // Calculate pixel dimensions for 18 inches at 150 DPI
            const widthInPixels = inches * dpi;
            const heightInPixels = inches * dpi;

            // Set image width and height in EXIF
            exifObj['0th'][piexif.ImageIFD.ImageWidth] = widthInPixels;
            exifObj['0th'][piexif.ImageIFD.ImageLength] = heightInPixels;

            // Convert the EXIF data back to binary
            const newExifStr = piexif.dump(exifObj);

            // Insert EXIF data back into the image (base64 format)
            const newImage = piexif.insert(newExifStr, base64Image);

            return newImage; // Return the image with updated DPI metadata
        } catch (error) {
            console.error('Error adding DPI to image:', error);
            return base64Image; // Return the original image if an error occurs
        }
    };

    canvasToImage = (val) => {
        const canvas = this.state.canvas;
        canvas.setOverlayImage(null, canvas.renderAll.bind(canvas));
        canvas.discardActiveObject();
        canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
        const { canvasWidth, canvasHeight, zoomlevel } = this.state.saveCanvasZoom;
        canvas.setHeight(canvasHeight);
        canvas.setWidth(canvasWidth);
        canvas.setZoom(zoomlevel);
        this.childRef.getZoom(zoomlevel, canvasWidth, canvasHeight);
        canvas.renderAll();
        
        const targetDPI = 150; // Desired DPI
        const inches = 18; // Physical size in inches
        
        // Calculate the pixel dimensions for 18 inches at 150 DPI
        const widthInPixels = inches * targetDPI; // 2700px
        const heightInPixels = inches * targetDPI; // 2700px
        
        // Create a circular crop on the canvas
        const circularCanvas = document.createElement('canvas');
        const ctx = circularCanvas.getContext('2d');
        
        // Set the circular canvas dimensions (18-inch circle in pixels)
        circularCanvas.width = widthInPixels;
        circularCanvas.height = heightInPixels;
        // Add these lines to improve image quality
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        // Fill the canvas with white background
        ctx.fillStyle = '#f0f0f0'; // Set the fill color to white
        ctx.fillRect(0, 0, widthInPixels, heightInPixels); // Fill the whole canvas

        // Clip the canvas into a circle
        ctx.beginPath();
        ctx.arc(widthInPixels / 2, heightInPixels / 2, widthInPixels / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.clip();
        
        // Draw the image onto the circular canvas
        const dataURL = canvas.toDataURL({
            format : 'png',
            quality : 1,
            multiplier : 10
        });
        const img = new Image();
        img.src = dataURL;
        
        img.onload = () => {
            ctx.drawImage(img, 0, 0, widthInPixels, heightInPixels);
            
            // Convert the circular PNG image to high-quality JPEG
            const pngDataURL = circularCanvas.toDataURL({
                format : 'png',
                quality : 1,
                multiplier : 10
            }); // 0.9 for high quality
            
            this.setState({
                previewImage: pngDataURL,
                canvasPreview: true,
                isOpen: true
            });
    
            if (val === 'modal') {
                this.setState({
                    previewImage: pngDataURL,
                    canvasPreview: true,
                    isOpen: true
                });
            } else {
                this.setState({
                    previewImage: pngDataURL,
                });
            }
        };
    }
    closeModal = () => {
        this.childRef.setbgImage(this.state.canvas)
        if(this.state.canvastype === 'photodart')
        {
            var canvasContainers1 = document.getElementsByClassName("canvas-container");
            if (canvasContainers1.length > 0) {
            var canvasContainer1 = canvasContainers1[0];
            canvasContainer1.style.border = "1px solid #C4CDD6"; // Adjust the value as per your preference
            canvasContainer1.style.borderStyle = "dashed";
            }
            var style = document.createElement('style');
            style.innerHTML = '.canvas-container:before { border: 1px solid #d00028; }'; // Adjust the value as per your preference
            document.head.appendChild(style);
        }
        this.setState({
            isOpen: false,
            continueButton : false,
            quantity : 1
        });
    };
    closeCartWarning = () => {
        this.setState({
            cartWarning : false,
            continueButton : false
        })
    }
    closeSaveWarning = () => {
        this.setState({
            saveWarning : false,
        })
    }
    cartLogin = () => {
        this.setState({
            disableButton : true
        })
        const canvas  = this.state.canvas
        canvas.discardActiveObject();
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        // Get current date and time
        const currentDate = new Date();
        // Format the date and time
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        // Create the formatted date and time string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const serializedObjects = canvas._objects.map((obj) => {
            return {
                ...obj.toJSON(),
                isLocked: obj.isLocked ? true : false,
                src: obj.src ? obj.src : null,
                imgName : obj.imgName ?  obj.imgName : null,
                editable : obj.editable ?  obj.editable : false,
                lockMovementX: obj.lockMovementX,
                lockMovementY: obj.lockMovementY,
                lockScalingX: obj.lockScalingX,
                lockScalingY: obj.lockScalingY,
                lockRotation: obj.lockRotation,
                listType: obj.listType,
                crossOrigin : obj.crossOrigin
            };
        });
        let payload = {
            "userId": "",
            "role": 'admin',
            "name":"New design",
            "productId": pid,
            "date" : formattedDate,
            "json": {
                "version": "5.3.0",
                "objects": serializedObjects,
                "background": canvas.backgroundColor,
                "height":  canvas.height,
                "width": canvas.width,
            },
            imageDetails : this.state.imageIds
        }
        localStorage.setItem('canvas',JSON.stringify(payload))
        this.setState({
            cartWarning : false,
            continueButton : false
        })
        setTimeout(() => {
            this.setState({
                disableButton : false
            })
        }, 2000);
        const currentUrl = window.location.href;
        let URL = WP_LOGIN+encodeURIComponent(currentUrl)
        window.location.href = URL;
    }
    saveLogin = () => {
        const canvas  = this.state.canvas;
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        // Get current date and time
        const currentDate = new Date();

        // Format the date and time
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Create the formatted date and time string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const serializedObjects = canvas._objects.map((obj) => {
            return {
                ...obj.toJSON(),
                isLocked: obj.isLocked ? true : false,
                src: obj.src ? obj.src : null,
                imgName : obj.imgName ?  obj.imgName : null,
                editable : obj.editable ?  obj.editable : false,
                lockMovementX: obj.lockMovementX,
                lockMovementY: obj.lockMovementY,
                lockScalingX: obj.lockScalingX,
                lockScalingY: obj.lockScalingY,
                lockRotation: obj.lockRotation,
                listType: obj.listType,
                crossOrigin : obj.crossOrigin
            };
        });
        let payload = {
            "userId": "",
            "role": 'admin',
            "name":"New design",
            "productId": pid,
            "date" : formattedDate,
            "json": {
                "version": "5.3.0",
                "objects": serializedObjects,
                "background": canvas.backgroundColor,
                "height":  canvas.height,
                "width": canvas.width,
            },
            imageDetails : this.state.imageIds
        }
        localStorage.setItem('canvas',JSON.stringify(payload))
        setTimeout(() => {
            this.setState({
                disableButton : false
            })
        }, 2000);
        const currentUrl = window.location.href;
        let URL = WP_LOGIN+encodeURIComponent(currentUrl)
        window.location.href = URL;
    }
    addtocart = () => {
        this.childRef.setbgImage(this.state.canvas)
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("u");
        const token = query.get("token");
        if(userId && token)
        {
            this.saveCanvas('addtocart')
        }else{
            this.setState({
                cartWarning : true,
                isOpen : false,
                cartLoader : false,
                quantity : 1
            })
        }
    }
    updatecart = (val) => {
        this.childRef.setbgImage(this.state.canvas)
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("u");
        const token = query.get("token");
        if(userId && token)
        {
            if(val === 'directUpdate')
            {
                this.canvasToImage('direct')
                this.saveCanvas('addtocart','directUpdate')
            }else{
                this.saveCanvas('addtocart','update')
            }
        }
    }
    updateCanvas = (canvas) => {
        this.setState(
            {
                canvas: canvas,
            },
            () => {
            }
        );
    };
    addCanvasInCanvasLIst = (item) => {
        const existingItem = this.state.allCanvasList.find(canvasItem => canvasItem.side === item.side);
        if (!existingItem) {
        this.state.allCanvasList.push(item);
        }
    };
    updateCanvases = (side) => {
        const item = this.state.allCanvasList?.find(
            (item) => item.side === side
        );
        this.updateCanvas(item.canvas);
    };
    toggleSidebar = (type) => {
        this.setState({ collapse: type, searchQuery : '' });
        this.setState({
            sidebarWidth: type ? 367 : 0,
        });
    };
    imgGallery = (id) => {
        const query = new URLSearchParams(window.location.search);
        let userId = query.get("u");
        const apiDynamicUrlAddress = appconfig.default.api1.host;
        const apiUrl = `${apiDynamicUrlAddress}/listImage`;
        let payload;
        if(id)
        {
            this.setState({
                imageIds : id
            })
            payload = {
                id : id
            }
        }else{
            payload = {
                userId : userId,
                role : this.state.isAdmin ? 'admin':'user'
            }
        }
        axios
        .post(apiUrl, payload)
        .then((response) => {
            if (response.data.code === 200) {
                this.setState({
                    selectedImages : response.data.data,
                    previewLoader : response.data.data,
                })
                this.isDeleted(false);
            }else{
                this.isDeleted(false);
                this.setState({
                    selectedImages :[],
                    previewLoader :[]
                })
            }
        })
        .catch((error) => {
            toast.error("List Image API Failed, Try again later", { position: toast.POSITION.TOP_RIGHT });
        });
    };
    isDeleted = (val) => {
        this.setState({
            isDeleted : val,
            deleteMessage : 'Deleting Image'
        })
    }
    previewLoaderImage = (val, previewData) => {
        if(val === 'response')
        {
            this.setState((prevState) => {
                const updatedDataImage = prevState.previewLoader.map((img) => {
                  if (img.fileName === previewData.fileName) {
                    // Update the loading state to false for matching objects
                    return {
                      ...img,
                      isLoading: false,
                      id: previewData.id,
                    };
                  }
                  return img; // Return the original object if it doesn't match
                });
                return { previewLoader: updatedDataImage };
              });
        }else{
        this.setState(prevState => ({
            previewLoader: [...prevState.previewLoader, previewData]
        }));
    }
    }
    closepreviewLoader = () => {
        this.setState({
            previewLoader : []
        })
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    saveCanvas = (val, update) => {
    const canvas  = this.state.canvas
    canvas.discardActiveObject();
    canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
    canvas.renderAll();
    const query = new URLSearchParams(window.location.search);
    const pid = query.get("pid");
    const userId = query.get("u");
    const token = query.get("token");
    // Get current date and time
    const currentDate = new Date();

    // Format the date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // Create the formatted date and time string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
    if(userId && token)
    {
        let uuid = this.state.design_unique_id ? this.state.design_unique_id : uuidv4();
        this.setState({
            appLoader : true,
            loaderMessage : 'Saving your Product...',
            cartLoader : true,
            cartLoaderMessage : 'Saving your Product...'
        })
        let apiDynamicUrlAddress2 = appconfig.default.api.host;
        let apiUrl2 = `${apiDynamicUrlAddress2}/user-profile/?user_id=${userId}&token=${token}`;
        axios
        .get(apiUrl2)
        .then((response)=>{
            const serializedObjects = canvas._objects.map((obj) => {
                return {
                    ...obj.toJSON(),
                    isLocked: obj.isLocked ? true : false,
                    src: obj.src ? obj.src : null,
                    imgName : obj.imgName ?  obj.imgName : null,
                    editable : obj.editable ?  obj.editable : false,
                    lockMovementX: obj.lockMovementX,
                    lockMovementY: obj.lockMovementY,
                    lockScalingX: obj.lockScalingX,
                    lockScalingY: obj.lockScalingY,
                    lockRotation: obj.lockRotation,
                    listType: obj.listType,
                    crossOrigin : obj.crossOrigin
                };
            });
            let payload = {
                "userId": userId ? userId : "23",
                "role": this.state.isAdmin ? "admin" : "user",
                "name":"New design",
                "productId": pid,
                "date" : formattedDate,
                "json": {
                    "version": "5.3.0",
                    "objects": serializedObjects,
                    "background": canvas.backgroundColor,
                    "height":  canvas.height,
                    "width": canvas.width,
                },
                "design_unique_id" : uuid
            }
            let apiDynamicUrlAddress = appconfig.default.api1.host;
            let apiUrl = `${apiDynamicUrlAddress}/saveProductImage`;
            axios
            .post(apiUrl, payload)
            .then((response)=>{
                if(response.status === 200)
                {
                    this.setState({
                        appLoader : false,
                        cartLoader : false
                    })
                    let did = response.data.Message.design_unique_id
                    this.setState({
                        design_unique_id : did
                    })
                    if(val === 'addtocart')
                    {
                        this.setState({
                            disableButton : true
                        })
                        if(update === 'update'){
                            this.setState({
                                cartLoader : true,
                                cartLoaderMessage : 'Updating your Product to Cart...'
                            })
                        }else if(update === 'directUpdate')
                        {
                            this.setState({
                                appLoader : true,
                                loaderMessage : 'Updating your Product to Cart...',
                            })
                        }else{
                            this.setState({
                                cartLoader : true,
                                cartLoaderMessage : 'Adding your Product to Cart...'
                            })
                        }
                        let payload= {
                            userId : userId,
                            productId : pid,
                            date : formattedDate,
                            file : this.state.previewImage,
                            design_unique_id : did
                        }
                        let apiDynamicUrlAddress = appconfig.default.api1.host;
                        let apiUrl = `${apiDynamicUrlAddress}/cartImage`;
                        let apiDynamicUrlAddress1 = appconfig.default.api.host;
                        let apiUrl1 = `${apiDynamicUrlAddress1}/add-product-to-cart?product_id=${pid}&user_id=${userId}&token=${token}&quantity=${this.state.quantity}&duid=${did}`;
                        axios
                        .post(apiUrl, payload)
                        .then((response)=>{
                            if(response.status === 200)
                            {
                                if(update === 'update')
                                {
                                    this.setState({
                                        cartLoader : false,
                                        isOpen : false,
                                        continueButton : false,
                                        quantity : 1
                                    })
                                    setTimeout(() => {
                                        this.setState({
                                            disableButton : false
                                        })
                                    }, 2000);
                                    toast.success("Product has been successfully updated to the cart", { position: toast.POSITION.TOP_RIGHT });
                                    window.location.href = CartLogin;
                                }else if(update === 'directUpdate'){
                                    this.setState({
                                        appLoader : false
                                    })
                                    setTimeout(() => {
                                        this.setState({
                                            disableButton : false
                                        })
                                    }, 2000);
                                    toast.success("Product has been successfully updated to the cart", { position: toast.POSITION.TOP_RIGHT });
                                    window.location.href = CartLogin;
                                }
                                else{
                                axios
                                .post(apiUrl1)
                                .then((response)=>{
                                    if(response.status === 200)
                                    {
                                        this.setState({
                                            cartLoader : false,
                                            isOpen : false,
                                            continueButton : false,
                                            quantity : 1
                                        })
                                        setTimeout(() => {
                                            this.setState({
                                                disableButton : false
                                            })
                                        }, 2000);
                                        toast.success("Product is Added to Cart", { position: toast.POSITION.TOP_RIGHT });
                                        window.location.href = CartLogin;
                                    }else{
                                        toast.error("Product is not Added to Cart", { position: toast.POSITION.TOP_RIGHT });
                                    }
                                })
                                .catch((error)=> console.log(error))
                                }
                            }else{
                                toast.error("Product is not Added to Cart", { position: toast.POSITION.TOP_RIGHT });
                            }
                        })
                        .catch((error)=> console.log(error))
                    }
                    else{
                        toast.success("Product has been saved successfully.", { position: toast.POSITION.TOP_RIGHT });
                        this.setState({
                            appLoader : false
                        })
                    }
                }else{
                    toast.error("Product is not Saved", { position: toast.POSITION.TOP_RIGHT });
                }
            })
            .catch((error)=> console.log(error))
        })
        .catch((error)=> {
            const canvas  = this.state.canvas
            const serializedObjects = canvas._objects.map((obj) => {
                return {
                    ...obj.toJSON(),
                    isLocked: obj.isLocked ? true : false,
                    src: obj.src ? obj.src : null,
                    imgName : obj.imgName ?  obj.imgName : null,
                    editable : obj.editable ?  obj.editable : false,
                    lockMovementX: obj.lockMovementX,
                    lockMovementY: obj.lockMovementY,
                    lockScalingX: obj.lockScalingX,
                    lockScalingY: obj.lockScalingY,
                    lockRotation: obj.lockRotation,
                    listType: obj.listType,
                    crossOrigin : obj.crossOrigin
                };
            });
            let payload = {
                "userId": "",
                "role": 'admin',
                "name":"New design",
                "productId": pid,
                "date" : formattedDate,
                "json": {
                    "version": "5.3.0",
                    "objects": serializedObjects,
                    "background": canvas.backgroundColor,
                    "height":  canvas.height,
                    "width": canvas.width,
                },
                imageDetails : this.state.imageIds
            }
            localStorage.setItem('canvas',JSON.stringify(payload))
            toast.error("Session Expired", { position: toast.POSITION.TOP_RIGHT });
            this.setState({
                appLoader : false,
                cartLoader : false,
                isOpen : false,
                continueButton : false,
                quantity : 1
            })
            // Get the current URL
            var currentURL = window.location.href;

            // Remove the "u" and "token" query parameters
            currentURL = removeQueryParam(currentURL, 'u');
            currentURL = removeQueryParam(currentURL, 'token');

            // Function to remove a query parameter from a URL
            function removeQueryParam(url, paramToRemove) {
            var urlParts = url.split('?');
            if (urlParts.length >= 2) {
                var params = urlParts[1].split('&');
                var newParams = [];
                for (var i = 0; i < params.length; i++) {
                var param = params[i].split('=');
                if (param[0] !== paramToRemove) {
                    newParams.push(params[i]);
                }
                }
                if (newParams.length > 0) {
                return urlParts[0] + '?' + newParams.join('&');
                } else {
                return urlParts[0];
                }
            } else {
                return url;
            }
            }
            let URL = WP_LOGIN+encodeURIComponent(currentURL)
            window.location.href = URL;
        })
    }
    else{
        this.setState({
            saveWarning : true
        })
    }
    }
    triggerState = () => {
        this.setState({
            triggerState : true
        })
    }
    multipleLayerDelete = () => {
        this.setState({
            sortedArray : this.state.canvas._objects
        })
    }
    draglayer = (result) => {
        const updatedItems = [...this.state.sortedArray];
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);
        this.setState({
            sortedArray : updatedItems
        })
        const previousBgColor = this.state.canvas.backgroundColor;
        this.state.canvas.clear();
        this.state.canvas.backgroundColor = previousBgColor;
        updatedItems.forEach((item)=>{
            this.state.canvas.setActiveObject(item)
            this.state.canvas.add(item)
            this.state.canvas.renderAll()
        })
        this.childRef.setbgImage(this.state.canvas)
        if(this.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.state.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.state.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }
    deleteLayer = (object) => {
        this.state.canvas.remove(object)
        this.setState({
            sortedArray : this.state?.canvas?._objects
        })
        const canvasData = this.state?.canvas?._objects
        canvasData.forEach((item)=>{
            this.state.canvas.setActiveObject(item)
            this.state.canvas.renderAll()
        })
        if(this.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.state.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.state.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }
    scrollToTop = () => {
        const tabList = document.querySelector('.tab-list');
        if (tabList) {
          tabList.scrollTop = 0;
        }
    };
    scrollToEnd = () => {
    const tabList = document.querySelector('.tab-list');
    if (tabList) {
        tabList.scrollTop = tabList.scrollHeight;
    }
    };
    saveZoom = (height,width,zoom) => {
    this.setState({
        saveCanvasZoom: {
            canvasWidth: width,
            canvasHeight: height,
            zoomlevel: zoom,
        },
        });
    }
    appLoader = (load, msg) => {
        this.setState({
            appLoader : load,
            loaderMessage : msg
        })
    }
    // handleQuantityChange = (event) => {
    //     let value = event.target.value.trim(); // Trim leading and trailing whitespaces
      
    //     // Check if the value is empty or a valid positive integer
    //     if (value === "" || (/^[1-9]\d*$/.test(value) && parseInt(value, 10) >= 1)) {
    //       // Ensure the value has a maximum of 3 digits
    //       if (value.length > 3) {
    //         value = value.slice(0, 3); // Trim to the first 3 characters
    //       }
      
    //       this.setState({ quantity: value });
    //     }
    // };
    handleQuantityPlus = () => {
        const { quantity } = this.state;
        if (quantity < 999) {
          this.setState({ quantity: quantity + 1 });
        }
      };
    
      handleQuantityMinus = () => {
        const { quantity } = this.state;
        if (quantity > 1) {
          this.setState({ quantity: quantity - 1 });
        }
      };
    handleKeyPress = (event) => {
        // Allow only digits and prevent entering more than 3 digits
        const isValidKeyPress = /^\d{0,3}$/.test(event.key);
        if (!isValidKeyPress) {
          event.preventDefault();
        }
    };
    handleBlur = () => {
        // If the input is empty, set it to a default value (e.g., 1)
        if (this.state.quantity === "") {
          this.setState({ quantity: 1 });
        }
    };
    handleResize = () => {
        this.setState({
            responsive : window.innerWidth
        })
        if(window.innerWidth >= 991)
        {
            this.setState({
                collapse : true
            })
        }else{
            this.setState({
                collapse : false
            })
        }
    }
    deleteProduct = () => {
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const userId = query.get("u");
        const duid = query.get("duid");
        let apiDynamicUrlAddress = appconfig.default.api1.host;
        let apiUrl = `${apiDynamicUrlAddress}/deleteProduct`;
        let config = {
            data: {
              "productId": pid,
              "userId": userId,
              "design_unique_id": duid ? duid : this.state.design_unique_id
            }
          };
        axios
        .delete(apiUrl, config)
        .then((response)=>{
            this.setState({
                ClearWarning : false
            })
            this.childRef.clearProduct()
        })
    }
    closeClearWarning = () => {
        this.setState({
            ClearWarning : false
        })
    }
    clearModal = () => {
        this.setState({
            ClearWarning : true
        })
    }
    mobiletoggleSidebar = () => {
        if(!this.state.mobilesidebar)
        {
            this.state.canvas.discardActiveObject()
        }
        this.setState({
            mobilesidebar : !this.state.mobilesidebar
        })
    }
    handleSearchQueryChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };
    handleWheel = (event) => {
        event.preventDefault();
      };
    isSpin = (val) => {
        this.setState({
            isSpin : val
        })
    }
    clickLogo = () => {
        window.location.href = this.state.logoUrl
    }
    render() {
        const {
            collapse,
            imggallery,
            previewImage,
            isLoader,
            productTips,
            productMaterial,
            productDimensions,
            productPrice,
            quantity,
            mobilesidebar
        } = this.state;
        const trustedHTML = { __html: productTips && productTips };
        const qty = new URLSearchParams(window.location.search).get('qty')
        const totalPrice = (qty ? qty : quantity) * productPrice;
        return (
            <>
                <ToastContainer/>
                <Container fluid>
                    {isLoader ? 
                    <GrowExample/>
                    :
                    <>
                    <Row style={{ borderBottom: "1px solid grey" }}>
                    <div className="logo-outer">
                        <div className="logo-header-outer">
                            <div className="logo-header-inner">                                
                                <img style={{ height: "inherit", position: "absolute", margin: "0.3vw 0 0 1vw", cursor : "pointer" }} src={photoDartLogo} alt="CAP" onClick={this.clickLogo}/>
                            </div>
                            <Toolbar
                                state={this.state}
                                allCanvasList={this.state.allCanvasList}
                                updateCanvas={this.updateCanvas}
                                canvasObj={this.state.canvasObj}
                                setImage={this.state.setImage}
                                bullet={this.state.bullet}
                                imageObject = {this.imageObject}
                                imageObjects = {this.state.imageObjects}
                                triggerState = {this.triggerState}
                                imgGallery = {this.imgGallery}
                                multipleLayerDelete = {this.multipleLayerDelete}
                            />
                        </div>
                    </div>
                    </Row>
                    <Row className="main-container">
                        <div className="main-container-inner">
                            <div className="largedevice" style={{position: "absolute", zIndex: "10" }}>
                                <Tabs
                                    defaultTab="vertical-tab-five"
                                    vertical
                                    className="vertical-tabs "
                                >
                                    <TabList className='tab-list' style={{ fontSize: "15px", borderRight: "none", paddingTop: "19px", marginRight: "10px", width: "110px", alignItems: "center", position : "relative", backgroundColor : "#f0f0f0" }}>
                                        <Tab
                                            tabFor="vertical-tab-five"
                                            className="lasttab mx-2 my-2"
                                            onClick={() => {
                                                this.toggleSidebar(true);
                                                this.scrollToTop();
                                                this.setState({
                                                    isMasking: false,
                                                    selectedtab : 'images'
                                                });
                                            }}
                                        >
                                            <div
                                                className="edit-box"
                                                id="ImageTab"
                                                style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}
                                            >
                                                <i className="fas fa-image fa-2x text-muted "></i>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Images
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-one"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                            {
                                                this.toggleSidebar(true)
                                                this.setState({
                                                    selectedtab : 'text'
                                                })
                                            }
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-font fa-2x text-muted"></i>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Text
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-eight"
                                            className="lasttab"
                                            onClick={() =>
                                            {
                                                this.toggleSidebar(true);
                                                this.setState({
                                                    selectedtab : 'color'
                                                });
                                            }
                                            }
                                        >
                                            <div className="edit-box"  style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-palette fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Color
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-three"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                            {
                                                this.toggleSidebar(true)
                                                this.setState({
                                                    selectedtab : 'clipart'
                                                });
                                            }
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-images fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Clip Art
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-four"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                            {
                                                this.toggleSidebar(true);
                                                this.setState({
                                                    selectedtab : 'shapes'
                                                });
                                            }
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-shapes fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Shapes
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-two"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>{
                                                this.toggleSidebar(true);
                                                this.scrollToEnd();
                                                this.setState({
                                                    selectedtab : 'layers'
                                                });
                                            }
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                {/* <i className="fas fa-font fa-2x text-muted"></i> */}
                                                <BsFillLayersFill className="layers-icon"/>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Layers
                                                </span>
                                            </div>
                                        </Tab>
                                        {/* {this.state.isMasking?this.toggleSidebar():null} */}
                                    </TabList>
                                    <div
                                        style={{ width: "100%" }}
                                        className="left-side-panel"
                                    >
                                        {collapse && (
                                            <LeftPanel
                                                key="leftpanel"
                                                allCanvasList={
                                                    this.state.allCanvasList
                                                }
                                                canvas={this.state.canvas}
                                                canvasObj={this.state.canvasObj}
                                                imggallery={imggallery}
                                                imgGallery={this.imgGallery}
                                                currentBookmark={
                                                    this.state.currentBookmark
                                                }
                                                // templateSize={{
                                                //     width: this.state
                                                //         .canvaswidth,
                                                //     height: this.state
                                                //         .canvasheight,
                                                // }}
                                                setImages={this.setImages}
                                                toggleSidebar = {this.toggleSidebar}
                                                collapse = {this.state.collapse}
                                                imageObject = {this.imageObject}
                                                imageObjects = {this.state.imageObjects}
                                                uploadedImages={this.uploadedImages}
                                                selectedImages = {this.state.selectedImages}
                                                backgroundColor = {this.state.backgroundColor}
                                                triggerState = {this.triggerState}
                                                sortedArray = {this.state.sortedArray}
                                                draglayer = {this.draglayer}
                                                deleteLayer = {this.deleteLayer}
                                                isAdmin = {this.state.isAdmin}
                                                previewLoaderImage = {this.previewLoaderImage}
                                                previewLoader = {this.state.previewLoader}
                                                closepreviewLoader = {this.closepreviewLoader}
                                                searchQuery = {this.state.searchQuery}
                                                handleSearchQueryChange = {this.handleSearchQueryChange}
                                                imageId = {this.state.imageId}
                                                isSpin = {this.isSpin}
                                                instruction = {this.state.instruction}
                                                selectedtab = {this.state.selectedtab}
                                                Deleted = {this.isDeleted}
                                                isDeleted = {this.state.isDeleted}
                                                deleteMessage = {this.state.deleteMessage}
                                            />
                                        )}
                                    </div>
                                </Tabs>
                            </div>

                            <LoadingOverlay
                            active={this.state.appLoader}
                            spinner
                            className="spinner-overlay"
                            text={this.state.loaderMessage}>
                            <div className="canvas-panel-canvas-area">

                                {/* Create Canvas after getting the size */}
                                {this.state.canvaswidth !== "" ? (
                                    <FabricCanvas
                                        ref={(ref) => (this.childRef = ref)}
                                        allCanvasList={this.state.allCanvasList}
                                        state={this.state}
                                        currentBookmark={this.currentBookmark}
                                        updateCanvas={this.updateCanvas}
                                        addCanvasInCanvasLIst={
                                            this.addCanvasInCanvasLIst
                                        }
                                        updateCanvases={this.updateCanvases}
                                        adminCanvasData = {this.state.adminCanvasData}
                                        saveCanvasZoom = {this.state.saveCanvasZoom}
                                        saveZoom = {this.saveZoom}
                                        triggerState = {this.triggerState}
                                        appLoader={this.appLoader}
                                        clearModal = {this.clearModal}
                                        multipleLayerDelete = {this.multipleLayerDelete}
                                        mobiletoggleSidebar = {this.mobiletoggleSidebar}
                                        responsive = {this.state.responsive}
                                        isSpin = {this.state.isSpin}
                                    />
                                ) : 
                                null}
                            </div>
                            </LoadingOverlay>
                        </div>
                    </Row>
                    </>
                    }
                    <div className={mobilesidebar ? 'mobilenav fixed-bottom mobilenav-active' : "mobilenav fixed-bottom "}>
                        <Tabs>
                            <div
                                className=" fixed-bottom"
                                style={{ position: "relative", zIndex: "auto", border : 'none' }}
                            >
                                {mobilesidebar && (
                                <>
                                <MobileLeftPanel toggle={this.toggle} activeTab = {this.state.activeTab}/>
                                {!collapse && (
                                    <LeftPanel
                                        key="leftpanel"
                                        allCanvasList={this.state.allCanvasList}
                                        canvas={this.state.canvas}
                                        canvasObj={this.state.canvasObj}
                                        imggallery={imggallery}
                                        imgGallery={this.imgGallery}
                                        currentBookmark={
                                            this.state.currentBookmark
                                        }
                                        // templateSize={{
                                        //     width: this.state.canvaswidth,
                                        //     height: this.state.canvasheight,
                                        // }}
                                        activeTab={this.state.activeTab}
                                        setActiveTab={this.setActiveTab}
                                        toggle={this.toggle}
                                        setImages={this.setImages}
                                        imageObject = {this.imageObject}
                                        imageObjects = {this.state.imageObjects}
                                        uploadedImages={this.uploadedImages}
                                        selectedImages = {this.state.selectedImages}
                                        backgroundColor = {this.state.backgroundColor}
                                        triggerState = {this.triggerState}
                                        sortedArray = {this.state.sortedArray}
                                        draglayer = {this.draglayer}
                                        deleteLayer={this.deleteLayer}
                                        isAdmin = {this.state.isAdmin}
                                        previewLoaderImage = {this.previewLoaderImage}
                                        previewLoader = {this.state.previewLoader}
                                        closepreviewLoader = {this.closepreviewLoader}
                                        mobiletoggleSidebar = {this.mobiletoggleSidebar}
                                        searchQuery = {this.state.searchQuery}
                                        handleSearchQueryChange = {this.handleSearchQueryChange}
                                        imageId = {this.state.imageId}
                                        isSpin = {this.isSpin}
                                        instruction = {this.state.instruction}
                                        Deleted = {this.isDeleted}
                                        isDeleted = {this.state.isDeleted}
                                        deleteMessage = {this.state.deleteMessage}
                                        
                                    />
                                )}
                                </>
                                )}
                            </div>
                        </Tabs>
                    </div>
                    {!this.state.isLoader && (
                    <span className="footer-outer" style={{ position: "fixed", bottom: 0, left: 0, width: "100%", zIndex: 999, backgroundColor: "rgb(255,255,255)", height: "60px", display: "flex", margin: "auto", textAlign: "center", boxShadow: " 0px 10px 30px rgb(50 50 50 / 75%)", justifyContent:"center" }}>
                        <button className="footer-btn my-2 btn btn-info" style={{cursor : "pointer"}} disabled={this.state.appLoader || this.state.disableButton} onClick={this.clickLogo}>
                        <div className="footer-btn-icon">
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                            Back
                        </div>
                        </button>
                        <button className="footer-btn mx-2 my-2 btn btn-info" onClick={new URLSearchParams(window.location.search).get('duid') ? (e) => this.updatecart('directUpdate') : this.saveCanvas} disabled={this.state.appLoader || this.state.disableButton}>
                        <div className="footer-btn-icon">
                            {new URLSearchParams(window.location.search).get('duid') ? 
                                <>
                                    <AiOutlineShoppingCart/>
                                </>
                                :
                                <>
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                </>
                            }
                            {new URLSearchParams(window.location.search).get('duid') ? 'Update Cart' : 'Save'}
                        </div>
                        </button>
                        <button className="continue-btn my-2 btn btn-warning" onClick={this.openModal} disabled={this.state.appLoader || this.state.disableButton}>
                        <div className="footer-btn-continue-icon">
                            Continue
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                        </button>
                    </span>
                    )}
                </Container>
                {this.state.continueButton ? 
                <LoadingOverlay
                active={this.state.cartLoader}
                spinner
                className={this.state.cartLoader ? "spinner-overlay-cart spinner-overlay-cart-loader" : "spinner-overlay-cart"}
                text = {this.state.cartLoaderMessage}
                >
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="xl"
                    id="previewModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                                <div className="modal-logo" style={{ height: "35px",width : "140px",left : 0 }}>                                
                                    <img style={{ height: "inherit", margin: "0.3vw 0 0 1vw", cursor : "pointer" }} src={photoDartLogo} alt="CAP" onClick={this.clickLogo}/>
                                </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <span className="preview-header">
                        REVIEW YOUR PROOF
                    </span>
                    <div className="preview-wizard-outer">
                        <div className="preview-wizard preview-wizard-active">
                            <p>PROOF</p>
                        </div>
                        <div className="preview-wizard">
                            <p>YOUR CART</p>
                        </div>
                        <div className="preview-wizard">
                            <p>CHECKOUT</p>
                        </div>
                        <div className="preview-wizard-receipt">
                            <p>RECEIPT</p>
                        </div>
                    </div>
                    {/* <div className="preview-title-outer">
                        <h5>Name of your Dartboard</h5>
                    </div> */}
                    <div className="preview-image">
                        <div className="preview-image-inner">
                            <div className="preview-img">
                                <img src={previewImage} alt = 'preview'/>
                            </div>
                            <div className="preview-edit-icon">
                                <MdModeEditOutline onClick={this.closeModal} disabled={this.state.cartLoader}/>
                            </div>
                        </div>
                        <div className="preview-content">
                            <h4>MATERIAL</h4>
                            <p>{productMaterial}</p>
                            <h4>SIZE</h4>
                            <p>{productDimensions}</p>
                            <div className="preview-qty-price-outer">
                            <div className="preview-qty">
                                <h4>QTY</h4>
                                {(this.state.isAdmin || new URLSearchParams(window.location.search).get('duid')) ? <p>{qty ? qty : quantity}</p> :
                                <div className="preview-qty-inner">
                                    <FaPlus className="qty-plus-icon" onClick={this.handleQuantityPlus}/>
                                    <input
                                    type="text"
                                    min="1"
                                    value={quantity}
                                    readOnly
                                    // onChange={this.handleQuantityChange}
                                    // onKeyPress={this.handleKeyPress}
                                    // onBlur={this.handleBlur}
                                    />
                                    <FaMinus className="qty-minus-icon" onClick={this.handleQuantityMinus}/>
                                </div>
                                
                            }
                            </div>
                           <div className="preview-price">
                                <h4>PRICE</h4>
                                <p>${totalPrice.toFixed(2)}</p>
                            </div>
                            </div>
                            {!this.state.isAdmin && new URLSearchParams(window.location.search).get('pid') && 
                            <div className="btn-edit-outer">
                                <button className="btn btn-edit" onClick={ new URLSearchParams(window.location.search).get('duid') ? this.updatecart : this.addtocart} disabled={this.state.cartLoader}>
                                {new URLSearchParams(window.location.search).get('duid') ? 'UPDATE CART' : 'ADD TO CART'}
                                </button>
                            </div>
                            
                            }
                        </div>
                    </div>
                    <div className="preview-content-tips">
                        <h4>
                            Tips For Reviewing Your Proof
                        </h4>
                        <p dangerouslySetInnerHTML={trustedHTML}></p>
                    </div>
                    </Modal.Body>
                    {/* <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.closeModal} disabled={this.state.cartLoader}>
                            Go Back and Edit Design
                        </button>
                    </Modal.Footer> */}
                </Modal>
                </LoadingOverlay>
                :''}
                <Modal
                    show={this.state.cartWarning}
                    onHide={this.closeCartWarning}
                    size="default"
                    id="CartWarningModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                Attention
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>Please log in before adding to the cart.</p>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.cartLogin}>
                            Login
                        </button>
                        <button className="btn btn-edit" onClick={this.closeCartWarning}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.saveWarning}
                    onHide={this.closeSaveWarning}
                    size="default"
                    id="saveWarningModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                Attention
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>Please log in before saving the product.</p>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.saveLogin}>
                            Login
                        </button>
                        <button className="btn btn-edit" onClick={this.closeSaveWarning}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.ClearWarning}
                    onHide={this.closeClearWarning}
                    size="default"
                    id="ClearWarningModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                Warning
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>Deleting this content will permanently remove all changes. Are you sure you want to proceed?</p>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.deleteProduct}>
                            Delete
                        </button>
                        <button className="btn btn-edit" onClick={this.closeClearWarning}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default App;